@use "../../global" as global;

.c-button {
  @include global.font-settings(18, 20, 1.5, 600);

  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  color: global.$color-white;
  text-align: center;
  background-color: global.$color-main;
  border-radius: 50px;
  transition: 0.2s;

  @include global.media(sp) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &--small {
    height: 40px;
    font-size: global.rem(16);
    line-height: 40px;
  }
}

.wp-element-button {
  @include global.font-settings(18, 20, 1, 600);

  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  color: global.$color-white;
  text-align: center;
  background-color: global.$color-main;
  border-radius: 50px;
  transition: 0.2s;

  @include global.media(sp) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &--small {
    height: 40px;
    font-size: global.rem(16);
    line-height: 40px;
  }
}
