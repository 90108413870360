@use "../../global" as global;

.p-404 {

  &__title-wrapper {
    margin-bottom: 3.5rem;
  }

  &__title {
    @include global.font-settings(20, 24, 1.5, 600);

    color: global.$color-main;
  }

  &__text {
    @include global.font-settings(16, 16, 1.5, 400);

    line-height: 1.8;
  }

  &__home-button {
    margin-top: 4rem;
  }
}
