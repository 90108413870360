@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap";
.p-404__title-wrapper {
  margin-bottom: 3.5rem;
}
.p-404__title {
  font-size: clamp(1.25rem, (100vw - 767px) * 0.015625 + 1.25rem, 1.5rem);
  font-weight: 600;
  line-height: 1.5;
  color: #2F5D50;
}
.p-404__text {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.8;
}
.p-404__home-button {
  margin-top: 4rem;
}

.c-button {
  font-size: clamp(1.125rem, (100vw - 767px) * 0.0078125 + 1.125rem, 1.25rem);
  font-weight: 600;
  line-height: 1.5;
  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2F5D50;
  border-radius: 50px;
  transition: 0.2s;
}
@media only screen and (max-width: 767px) {
  .c-button {
    width: 100%;
  }
}
.c-button:hover {
  opacity: 0.8;
}
.c-button--small {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
}

.wp-element-button {
  font-size: clamp(1.125rem, (100vw - 767px) * 0.0078125 + 1.125rem, 1.25rem);
  font-weight: 600;
  line-height: 1;
  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #2F5D50;
  border-radius: 50px;
  transition: 0.2s;
}
@media only screen and (max-width: 767px) {
  .wp-element-button {
    width: 100%;
  }
}
.wp-element-button:hover {
  opacity: 0.8;
}
.wp-element-button--small {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
}