@charset "UTF-8";

// ==========================================================================
// Variables
// ==========================================================================

// font
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap";

$font-base: "Noto Sans JP", sans-serif;
$baseFontSize: 16;

// width
$width-base: 1140px;
$width-inner: 1120px;

// color
$color-white: #FFFFFF;
$color-black: #333333;
$color-lightgray: #F6F6F6;
$color-gray: #E2E2E2;
$color-red: #EB5757;
$color-main: #2F5D50;
$color-bg: #EBEBEB;

// height
$height-header-upper: 74px;
$height-header-under: 74px;
$header-height-pc: 148px;
$header-height-sp: 74px;
$width-min: 350px;

// z-index
$layer: (
  modal : 100,
  header : 20,
  tooltip : 10,
  default : 1);

// breakpoint
$breakpoints: (
  sp: "only screen and (max-width: 767px)",
  tb: "only screen and (max-width:1023px)",
);

// breakpoint
$min-breakpoints: 350;
$sp-breakpoints: 767;
$tb-breakpoints: 1023;
$min-breakpoints-px: $min-breakpoints * 1px;
$sp-breakpoints-px: $sp-breakpoints * 1px;
$tb-breakpoints-px: $tb-breakpoints * 1px;
$breakpoints: (
  min: "only screen and (max-width: #{$min-breakpoints-px})",
  sp: "only screen and (max-width: #{$sp-breakpoints-px})",
  tb: "only screen and (max-width: #{$tb-breakpoints-px})",
);
